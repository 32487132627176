@charset "utf-8";
@import 'import/var';

select,
input {
	font-size: $fontsize16;
}
.text_frame input,
.textarea_frame textarea {
	font-size: $fontsize16;
}

body {
	font-size: $sp_default_font_size;
	line-height: $sp_default_line_height;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************
## Layout
*/

.root {
	overflow: hidden;
	padding-top: $sp_header_height;

	.main_content {
		@media #{$sp} {
			//padding-top: 150px;
		}
		@media #{$xs} {
			//padding-top: 110px;
		}
	}
}

.wrapper {
	width: 100%;
	min-width: 100%;
}
@mixin container {
	box-sizing: border-box;
	margin-left: auto;
	margin-right: auto;
	padding-left: $sp_padding;
	padding-right: $sp_padding;
	// overflow: hidden;

	// @media #{$pc-s} {
	// 	padding-left: $pc_padding;
	// 	padding-right: $pc_padding;
	// }

	// @media #{$sp-l} {
	// 	padding-left: $sp_padding;
	// 	padding-right: $sp_padding;
	// }
	// @media #{$sp-m} {
	// 	padding-left: $sp_padding;
	// 	padding-right: $sp_padding;
	// }
}

.sp_container,
.mb_container,
.container {
	@include container;

	&-nopadding {
		padding-left: 0;
		padding-right: 0;
	}
}
@media #{$ipc} {
	.ipc_container {
		@include container;
	}
}
@media #{$isp} {
	.isp_container {
		@include container;
	}
}

.sp_padding,
.padding {
	padding-left: $sp_padding;
	padding-right: $sp_padding;
}

// .main_column {
// 	margin-bottom: 30px;
// }

.main_content {
	padding-bottom: 70px;

	img {
		display: block;
	}
}

.sub_column {
	// @include sp_unindent;
	// margin-bottom: 100px;
}

.scroll_to_top {
	// right: $sp_padding;
	right: 0;
}

.sp_unindent {
	@include sp_unindent;
}
.sp_cell + .sp_cell {
	margin-left: 1em;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Utility - Cosmetic

ユーティリティー設定。
このサイト専用というわけでもない共通設定。

<div class="_h3">.sp</div>
PCの時だけ表示される。

<div class="_h3">.sp_block</div>
SPの時だけ、ブロック要素になる。

<div class="_h3">.tel_link</div>
SPの時だけ、リンクになる。(クリックで電話するリンク)

*/
.sp_none,
.root .sp_none {
	display: none;
}
.block,
.sp_block {
	display: block;
}
@media #{$ipc} {
	.ipc_block {
		display: block;
	}
}
@media #{$isp} {
	.isp_block {
		display: block;
	}

	.ipc_block {
		+ .ipc_block {
			margin-left: .7em;
		}
	}
}

.sp_click {
	cursor: pointer;
}
.scell {
	display: block;
	margin-right: 0;
}

.tel_link {

	// .root &,
	// .root &:hover {
	// 	text-decoration: underline;
	// 	color: palette('link');
	// }
	&-no {
		text-decoration: none;
		pointer-events: none;
		color: inherit;
	}
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Component - Base

コンポーネント設定。
各ページでよく使い回す記述。
このサイト専用の共通設定。

*/


/**************************

## - 段組設定 2列 3列 4列

SPの時だけ、中央に等間隔で余白を作り、width: 100%; を守って列になる。
ul でなくてもいい。

**2列 .sp_column2**

	<ul class="sp_column2">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**3列 .sp_column3**

	<ul class="sp_column3">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**4列 .sp_column4**

	<ul class="sp_column4">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

*/
.sp_column2 {
	@include columns(2, 10px);
}

.sp_column3 {
	@include columns(3, 10px);
}

.sp_column4 {
	@include columns(4, 10px);
}

.sp_column2,
.sp_column3,
.sp_column4 {
	img {
		margin-left: auto;
		margin-right: auto;
	}
}

.pc_column2,
.pc_column3,
.pc_column4 {
	img {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
}

/**************************
.pc_column
*/

.pc_column2:not(.sp_column2):not(.sp_column3):not(.sp_column4) {
	> *:not(:last-child) {
		margin-bottom: 1em;
	}
}
.pc_column3:not(.sp_column2):not(.sp_column3):not(.sp_column4) {
	> *:not(:last-child) {
		margin-bottom: 1em;
	}
}
.pc_column4:not(.sp_column2):not(.sp_column3):not(.sp_column4) {
	> *:not(:last-child) {
		margin-bottom: 1em;
	}
}

.pc_column2b:not(.sp_column2):not(.sp_column3):not(.sp_column4) {
	> *:not(:last-child) {
		margin-bottom: 1em;
	}
}
.pc_column3b:not(.sp_column2):not(.sp_column3):not(.sp_column4) {
	> *:not(:last-child) {
		margin-bottom: 1em;
	}
}
.pc_column4b:not(.sp_column2):not(.sp_column3):not(.sp_column4) {
	> *:not(:last-child) {
		margin-bottom: 1em;
	}
}



/**************************
	.table-column2
*/
.table {
	&-column2 {
		// display: block;
		width: 100% !important;
		max-width: 100% !important;

		thead,
		tbody,
		tfoot,
		tr,
		th,
		td {
			display: block;
			box-sizing: border-box;
			float: left;
			clear: both;

			.root & {
				width: 100%;
			}
		}
		tr th,
		th + td {
			border-top: 0 none;
		}
		tr td {
			padding-bottom: 1em;;
		}
		thead,
		tbody {
			th,
			td {
				width: 100% !important;
				text-align: left;
				// border-left: 0 none;
				// border-right: 0 none;
			}
			td + td {
				border-top: 0 none;
			}
		}
	}
	&.table-column2 {
		&:not(.table-noborder) {
			tr:first-child {
				th:first-child,
				td:first-child {
					border-top: 1px solid palette('table', 'border');
				}
			}
		}
	}
}

/* form */
.input_frame.node,
.input_frame.node input {
	width: 60px;
	display: inline-block;
}
.input_frame.node2,
.input_frame.node2 input {
	width: 70px;
}
form .frame .node {
	margin-right: 3px;
}


.sp_center {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	display: block;

	.img {
		display: inline-block;
		margin-left: auto;
		margin-right: auto;
	}
}


.footer {
	position: relative;
	top: 0;
	left: 0;
	&-scroll_to_top_stop {
		.scroll_to_top {
			position: absolute;
			top: -70px;
			bottom: auto;
		}
	}
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Project

特定ページ専用、効果範囲が限定的な記述。
コンテンツ内容に密接で、汎用性がなく、機能の付け替えを想定していない。

*/

.pc_header_menu_area {
	display: none;
}
.header {
	width: 100%;
	//position: absolute;
	top: 0;
	left: 0;
	z-index: 10001;
	&_bottom {
		display: none;
	}
}
.header__search_input_area {
	display: none;
}

body {
	.root {
		position: relative;
		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.6);
			position: absolute;
			top: 0;
			left: 0;
			z-index: 10005;
			opacity: 0;
			transition: 0.2s;
			pointer-events: none;
		}
	}
}
body.menu-opened {
	.root {
		position: relative;
		&::before {
			opacity: 1;
		}
	}
}
.sp_menu_area {
	width: 100%;
	height: 100%;
	display: none;
	overflow: auto;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 10005;
	&_wrapper {
		width: 65vw;
		min-width: 200px;
		height: 100vh;
		background-color: #1e6e88;
		position: fixed;
		top: 0;
		right: 0;
		transition: 1s;
	}

	.logo {
		width: 100%;
		height: calc(190px - 66px);
		background-color: #fff;
		display: flex;
		justify-content: center;
		padding-top: 66px;
		img {
			display: block;
			width: 120px;
			height: 100px;
		}
	}
}

.sp_menu {
	padding: 20px;
	box-sizing: border-box;
	li {
		padding-top: 5px;
		padding-bottom: 5px;
	}
	a {
		display: inline-block;
		box-sizing: border-box;
		font-weight: 500;
		font-size: $fontsize15;
		padding-top: 3px;
		padding-bottom: 3px;
		color: #fff;
	}
}
.sp_menu_area_wrapper {
	overflow-y: scroll;
}